import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: 'https://09a358fbec9969d2a2cefc0491c3e777@o4506626963996672.ingest.sentry.io/4506676504756224',
	tracesSampleRate: 1.0,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	integrations: [replayIntegration()],
	enabled: process.env.NODE_ENV !== 'development'
});

export const handleError = handleErrorWithSentry();
